<template>
  <div>
    <!-- DRAWER -->
    <v-navigation-drawer
      id="core-drawer"
      v-model="Mdrawer"
      v-bind="Entorno.drawer"
    >
      <v-divider />

      <!-- MENU -->
      <AppMenu />

    </v-navigation-drawer>

    <!-- BAR -->
    <v-app-bar v-bind="Entorno.bar">
      <v-app-bar-nav-icon @click.stop="Mdrawer = !Mdrawer" />

      <v-toolbar-title>
        <div class="conflex">
          <div :class="Entorno.bar.titulo_class">
            {{ Entorno.bar.titulo }}
            <span :class="Entorno.bar.subtitulo_class"> {{ Entorno.bar.subtitulo }}</span>
            <span :class="Entorno.bar.aplicacion_class"> {{ Entorno.bar.aplicacion }}</span>
          </div>

          <v-spacer> </v-spacer>
          <div v-if="!$store.state.G.PRODUCTION" style="margin-left:50px;">ESTA VERSIÓN ES SÓLO PARA PRUEBAS</div>

          <div style="margin-left:50px">
            <!-- <span> {{ $store.state.G.ROL }}</span> -->
            <!--  <span :class="Entorno.bar.titulo_class"> {{ $store.state.G.USUDAT.name }}</span> -->
          </div>
        </div>
      </v-toolbar-title>

      <v-spacer/>

      <!-- <div class="conflex pr-4"> -->

      <div v-if="$store.state.login_estado">
        <!-- <v-btn v-if="$store.state.login_estado"
          color="light-blue darken-3"
          small
          title="Ayuda"
          style="margin-right:50px"
          @click="ayuda(9)">
          Ayuda
          <v-icon style="margin-left:20px">mdi-help-circle</v-icon>
        </v-btn> -->

        <v-menu rounded offset-y class="mr-6">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="light-blue darken-3"
              small dark
              v-bind="attrs"
              v-on="on"
            >
            <v-icon> mdi-help-circle</v-icon>
              Ayudas
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="(item, index) in ayudas"
              :key="index"
              link
              @click="ayuda(index)">
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-spacer/>

          <!-- <avisos_campana ref="avisos_campana" @aviso="$emit('aviso', $event)"></avisos_campana> -->

          <!-- <v-btn
            @click="$emit('aviso', { accion:'avisosExp'})"
            v-if="$store.state.login_estado? (typeof $store.state.G.USUDAT.aviso1!== 'undefined'? $store.state.G.USUDAT.aviso1 : 0) : 0"
            icon
            x-small>
                <v-badge
                color="white green--text"
                overlap
                title="Avisos Facturación"
                offset-x="3"
                offset-y="10"
                :content="avisos1N">
                  <v-icon icon color="green">{{ 'mdi-bell' }}</v-icon>
              </v-badge>
          </v-btn>

          <v-btn
              v-if="$store.state.login_estado? (typeof $store.state.G.USUDAT.aviso2!== 'undefined'? $store.state.G.USUDAT.aviso2 : 0) : 0"
            style="margin-left:40px"
            @click="$emit('aviso', { accion:'avisosFras'})"
            icon
            x-small>
              <v-badge
                color="white red--text"
                overlap
                title="Avisos Facturación"
                offset-x="3"
                offset-y="10"
                :content="avisos1N">
                  <v-icon icon color="#FF4500">{{ 'mdi-bell' }}</v-icon>
              </v-badge>
          </v-btn>

        </div> -->
      </div>

        <AppBarInfo v-on:logOut="logInOut"/>

          <!-- <div  class="conflex" style="margin-left:40px;align-items:center">
            <div class="blue-grey--text text--lighten-2" style="margin:10px">
              {{ $store.state.login_estado? $store.state.G.USUARIO : ''}}
            </div>
          <v-btn
            color="blue-grey lighten-1"
            fab
            x-small
            :title="$store.state.login_estado? 'Cerrar Sesión' : 'Iniciar Sesión'"
            @click="logInOut">
              <v-icon> {{ $store.state.login_estado?"mdi-account-lock":"mdi-lock" }}</v-icon>
          </v-btn>
        </div> -->

    </v-app-bar>
  </div>
</template>

<script>
import plugs from "@/common/general_plugs";
//import avisos_campana from './avisos_campana.vue';
import AppBarInfo from './AppBarInfo.vue';
const AppMenu = () => plugs.groute("AppMenu.vue", "comp");
/* const avisosExp = () => plugs.groute("avisosExp.vue", "comp"); */

export default {
  components: { AppMenu, AppBarInfo
                //avisos_campana
  },

  data() {
    return {
      Entorno: this.$cfg.app,
      Mdrawer: true.commit,
      component_M:null,
      modal:false,
      accion:'',
      accionRow: {},
      disparo:false,
      avisos1N:30,

      guias: [
        { title: 'Guia Práctica', file: 'guia_admon' }
      ],
      ayudas: [
        { title: 'Guia Prueba', file: 'guia_prueba'},
        { title: 'Manual Operativo CASTELLON SENIOR', file: 'manual_operativo_castellon'},
        { title: 'Manual Operativo ESPECIAL', file: 'manual_operativo_especial'},

      ],

    };
  },

  mounted() {
    this.$root.$avisos_campana = this.$refs.avisos_campana;
  },


  methods: {
    async logInOut() {
      if (this.$store.state.login_estado) await this.logOut();
      this.logIn()
    },


    //
    async logOut() {
      let args = { tipo: "", api: "login", accion: "logout" };
      await this.$store.dispatch("ajaxRequest", { args: args });

      this.$store.commit('logout');
      this.$router.push({ path: 'login' });
    },

    //
    logIn() {
      if (this.$router.options.routes.length) {
        if (this.$router.options.routes[this.$router.options.routes.length - 1].redirect=='/') return;
      }

      this.$router.push({ path: 'login' });
    },


    /* ayuda() {
      window.open('https://funescom.com/ayuda/guia_ctd_admon.pdf', "_blank");
    } */
    ayuda(tp=0) {
      //let url=this.$store.state.G.urlAyuda;
      let file=(tp==9 ? this.guias[0].file : this.ayudas[tp].file );
      window.open(`/ayuda/${file}.pdf`, "_blank");
    }
  },

  computed:{
      ayudasycampanas() {
        return !(this.$store.state.G.USUDAT.ndep==1 && this.$store.state.G.USUDAT.nivel==2);
      }
  }

}
</script>